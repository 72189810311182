<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-body">
            <h5>
                البيانات العامة
            </h5>
            <hr>
            <div class="form-group">
              <span for="">إسم المدرسة</span>
              <input type="text"
                class="form-control" v-model="school_name" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <span for="">إسم مدير المدرسة</span>
              <input type="text"
                class="form-control" v-model="admin_name" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <span for="">إسم الرئيس المباشر</span>
              <input type="text"
                class="form-control" v-model="boss_name" placeholder="اكتب هنا...">
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save()">
                    حفظ
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            school_name: "",
            admin_name: "",
            boss_name: "",
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
      if(!checkPer("teachers|settings")){
          this.$router.push('/NotPermitted')
          return false;
      }
        var g = this;
        $.post(api + '/user/teachers/info', {
            jwt: g.user.jwt
        }).then(function(r){
            g.school_name = r.response.school_name;
            g.admin_name = r.response.admin_name;
            g.boss_name = r.response.boss_name;
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/user/teachers/info/save', {
                jwt: g.user.jwt,
                school_name: g.school_name,
                admin_name: g.admin_name,
                boss_name: g.boss_name
            }).then(function(r){
                alert("تم الحفظ", 100)
            })
        }
    }
}
</script>

<style>

</style>